import styles from './tokenomics.module.scss';
import movingPepe from '../../images/jesusballing.gif';

export const Tokenomics = () => {
  return (
    <section className={styles.wrap} id='tokenomics'>
      <div className={styles.animatedPepe}>
        <div className={styles.animatedPepeInner}>
          <img src={movingPepe} className={styles.movingPepeImage} alt='pepe' />
        </div>
      </div>
      <h2 className={styles.title}>OUR TOKENOMICS</h2>
      <div className={styles.content}>
        <div className={styles.inner}>
          <div>SYMBOL: $JEETUS</div>
          <div>TOKEN NAME: JEETUS</div>
          <div>Address: <span className={styles.contract}>Eq4jaw51TWzPnZewXsVrJuDL2d9GhiViUFyf9hhhgtdy</span></div>
          <div>TOTAL SUPPLY: 1 billion</div>
          <div>DEVs SMITED BY GOD</div>
          <div>TAX: 0/0</div>
        </div>
      </div>
    </section>
  )
}