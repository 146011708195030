
import CoinMarketIcon from '../../icons/CoinMarketIcon';
import CoinGeckoIcon from '../../icons/CoinGeckoIcon';
import RadiumIcon from '../../icons/RadiumIcon2';

import dextools from '../../images/partners/dextools.png';

export const partnersList = [

  {
    name: 'CMC',
    link: 'https://coinmarketcap.com/',
    icon: CoinMarketIcon,
  },
  {
    name: 'CG',
    link: 'https://www.coingecko.com/',
    icon: CoinGeckoIcon,
  },
  {
    name: 'Raydium',
    link: 'https://raydium.io/',
    icon: RadiumIcon,
  },
  {
    name: 'Dextools',
    link: 'https://www.dextools.io/',
    image: dextools,
  }
]