import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={2336}
    height={640}
    viewBox="0 0 2336 640"
    fill="none"
    {...props}
  >
    <g clipPath="url(#aRadiumIcon2)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m802.9 395-28.8-50H732v50h-24.8V244.8h74.7c9.1-.1 18.1 2.4 25.9 7.1 7.8 4.7 14.2 11.4 18.5 19.5 4.3 8.1 6.2 17.2 5.7 26.3-.5 9.1-3.5 18-8.7 25.5-5.4 8-13 14.2-21.9 17.9L832 395h-29.1Zm-21.1-124.7H732v49.9h49.9c3.3 0 6.5-.7 9.5-2s5.7-3.2 7.9-5.6c3.4-3.5 5.8-7.9 6.7-12.8.9-4.8.4-9.8-1.4-14.4-1.9-4.5-5-8.4-9.1-11.2-4.1-2.8-8.8-4.3-13.7-4.3v.4Zm265.6-25.5L972.5 395h27.8l47-94.1 46.9 94.1h27.8l-74.6-150.2Zm315.2 0h-31.7l-42.6 55.4-42.4-55.4h-31.8l62.4 80.1V395h24.8v-71.3l61.3-78.9Zm127.1 0h49.7c19.8.1 38.7 8.1 52.6 22.2 14 14 21.9 33.1 22 52.9-.1 19.9-8 38.9-22 53-14 14.1-32.9 22-52.7 22.1h-49.7V244.8h.1Zm49.7 124.7c13.2 0 25.8-5.3 35.1-14.7 9.3-9.4 14.6-22.1 14.6-35.3-.2-13.2-5.4-25.9-14.7-35.2-9.3-9.3-21.9-14.7-35-14.8h-25.1v100h25.1Zm240.5-124.7h-24.8V395h24.8V244.8Zm265.4 87.5c.1 8.3-1.4 16.5-4.6 24.1-3.1 7.6-7.8 14.5-13.8 20.2-8.7 8.8-19.8 14.7-31.9 17.1-12.1 2.4-24.6 1.2-36-3.6-11.4-4.7-21.1-12.8-27.9-23.1-6.8-10.3-10.4-22.4-10.4-34.8v-87.4h24.8v87.4c0 10 3.9 19.5 11 26.5 7 7 16.5 11 26.4 11 9.9 0 19.4-4 26.4-11s10.9-16.6 10.9-26.5v-87.4h25v87.5h.1ZM2211 395v-89.8l50 50.1 49.8-50.1V395h25.2V244.8l-75 75.1-74.7-75.1V395h24.7Z"
        clipRule="evenodd"
      />
      <path
        fill="url(#bRadiumIcon2)"
        d="M522.9 240.1v223.3L275.8 606.8 28.6 463.4V176.6L275.9 33l189.9 110.3 28.7-16.6L275.9-.2 0 159.9V480l275.9 160.1L551.8 480V223.5l-28.9 16.6Z"
      />
      <path
        fill="url(#cRadiumIcon2)"
        d="M206.7 463.6h-41.3V324.2h137.8c13-.1 25.5-5.4 34.7-14.8 9.2-9.3 14.3-21.9 14.4-35 .1-6.5-1.2-12.9-3.7-18.9-2.5-6-6.2-11.4-10.8-15.9-4.5-4.6-9.9-8.3-15.8-10.8s-12.3-3.8-18.8-3.7H165.4v-42.4h137.9c24.1.1 47.3 9.9 64.3 27 17.1 17.2 26.7 40.4 26.9 64.7.1 18.6-5.5 36.7-16.1 51.9-9.8 14.5-23.6 25.9-39.7 32.7-16 5.1-32.6 7.6-49.3 7.5h-82.7v97.1Z"
      />
      <path
        fill="url(#dRadiumIcon2)"
        d="M393.5 460.1h-48.2l-37.2-65.2c14.7-.9 29.2-3.9 43.1-8.9l42.3 74.1Z"
      />
      <path
        fill="url(#eRadiumIcon2)"
        d="m494.1 193.7 28.5 15.9 28.5-15.9V160l-28.5-16.6-28.5 16.6v33.7Z"
      />
    </g>
    <defs>
      <linearGradient
        id="bRadiumIcon2"
        x1={568.529}
        x2={-17.305}
        y1={203.585}
        y2={436.612}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C200FB" />
        <stop offset={0.49} stopColor="#3772FF" />
        <stop offset={0.49} stopColor="#3773FE" />
        <stop offset={1} stopColor="#5AC4BE" />
      </linearGradient>
      <linearGradient
        id="cRadiumIcon2"
        x1={557.349}
        x2={-28.484}
        y1={175.481}
        y2={408.509}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C200FB" />
        <stop offset={0.49} stopColor="#3772FF" />
        <stop offset={0.49} stopColor="#3773FE" />
        <stop offset={1} stopColor="#5AC4BE" />
      </linearGradient>
      <linearGradient
        id="dRadiumIcon2"
        x1={615.685}
        x2={29.852}
        y1={322.143}
        y2={555.17}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C200FB" />
        <stop offset={0.49} stopColor="#3772FF" />
        <stop offset={0.49} stopColor="#3773FE" />
        <stop offset={1} stopColor="#5AC4BE" />
      </linearGradient>
      <linearGradient
        id="eRadiumIcon2"
        x1={552.937}
        x2={-32.894}
        y1={164.504}
        y2={397.531}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C200FB" />
        <stop offset={0.49} stopColor="#3772FF" />
        <stop offset={0.49} stopColor="#3773FE" />
        <stop offset={1} stopColor="#5AC4BE" />
      </linearGradient>
      <clipPath id="aRadiumIcon2">
        <path fill="#fff" d="M0 0h2336v640H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
