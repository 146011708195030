import { content } from './carried.content';
import styles from './carried.module.scss';
import movingPepe from '../../images/jesusballing.gif';

export const Carried = () => {
  return (
    <section className={styles.wrap}>
    <div className={styles.animatedPepe}>
      <div className={styles.animatedPepeInner}>
        <img src={movingPepe} className={styles.movingPepeImage} alt='pepe' />
      </div>
    </div>
      <h2 className={styles.title}>JEETUS ROADMAP</h2>
      <ul className={styles.content}>
        {
          content.map((item) => {
            return (
              <li key={item.title} className={styles.card}>
                <div className={styles.cardInner}>
                  <h3 className={styles.cardTitle}>{item.title}</h3>
                  <ul className={styles.cardList}>
                    {
                      item.items.map((cardItem) => {
                        return (
                          <li key={cardItem} className={styles.cardItem}>{cardItem}</li>
                        )
                      })
                    }
                  </ul>
                  </div>
              </li>
            )
          })
        }
      </ul>
    </section>
  )
}