import { bunnyBscList, bunnyBscPartnerList, bunnySolList } from './team.content';
import styles from './team.module.scss';
import movingPepe from '../../images/jesusballing.gif';

export const Team = () => {
  return (
    <section className={styles.wrap}>
      <div className={styles.animatedPepe}>
        <div className={styles.animatedPepeInner}>
          <img src={movingPepe} className={styles.movingPepeImage} alt='pepe' />
        </div>
      </div>
      <h2 className={styles.title}>THE JEETUS Sotry</h2>
      <div>
        <div className={styles.content}>
          <h3 className={styles.teamName}>The roman chudpire</h3>
          <p className={styles.text}>
            In the year 30 AD a bunch of inbred wigwards killed jeetus and Rome was rugged to punish them for their sins
          </p>
          
        </div>
        <div className={styles.content}>
          <h3 className={styles.teamName}>JEETUS TECHNOLOGY</h3>
          <ul className={styles.list}>
            {
              bunnySolList.map((item) => {
                return (
                  <li className={styles.item} key={item}>{item}</li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </section>
  )
}