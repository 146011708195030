import styles from './buy.module.scss';
import pepe from '../../images/logo.png';
import movingPepe from '../../images/jesusballing.gif';

export const Buy = () => {
  return (
    <section className={styles.wrap} id='how-to-buy'>
      <div className={styles.animatedPepe}>
        <div className={styles.animatedPepeInner}>
          <img src={movingPepe} className={styles.movingPepeImage} alt='pepe' />
        </div>
      </div>
      <h2 className={styles.title}>HOW TO BUY $JEETUS</h2>
      <div className={styles.content}>
        <div className={styles.imageBlock}>
          <img src={pepe} alt="pepe" className={styles.image} />
        </div>
        <div className={styles.stepsBlock}>
          <ul className={styles.list}>
            <li>1. Create phantom wallet by downloading the chrome extension from this <a className={styles.link} href='https://phantom.app' target='_blank'>link</a></li>
            <li>2. Buy $SOL from CEX platforms like Binance, MEXC and Coinbase</li>
            <li>3. Send $SOL to your phantom wallet</li>
            <li>4. Trade for $JEETUS at pump.fun by clicking on the this <a className={styles.link} href='https://www.pump.fun/Eq4jaw51TWzPnZewXsVrJuDL2d9GhiViUFyf9hhhgtdy'>link</a></li>
          </ul>
        </div>
      </div>
    </section>
  )
}