import { partnersList } from './footer.content';
import TelegramIcon from '../../icons/TelegramIcon';
import XIcon from '../../icons/XIcon';
import nakedPepe from '../../images/jesusballing.gif';
import bunny from '../../images/chudjackcope.gif';
import styles from './footer.module.scss';

export const Footer = () => {
  return (
    <footer className={styles.wrap}>
      <div className={styles.animals}>
        <div className={styles.pepeWrap}>
          <img src={nakedPepe} alt='Pepe' className={styles.pepeImage} />
        </div>
        <div className={styles.bunnyWrap}>
          <img src={bunny} alt='Crazy bunny' className={styles.bunnyImage} />
        </div>
      </div>
      <div className={styles.partners}>
        <ul className={styles.partnersList}>
          {
            partnersList.map((item) => {
              return (
                <li className={styles.partnerItem} key={item.name}>
                  <a className={styles.parynerLink} href={item.link} target='_blank'>
                    {item.icon ? <item.icon className={styles.partnerIcon} /> : <img alt={item.name} src={item.image} className={styles.partnerIcon} />}
                  </a>
                </li>
              )
            })
          }
        </ul>
      </div>
      <div className={styles.contacts} id="contacts">
        <div className={styles.socials}>
          <a href='https://t.me/' target='_blank' className={styles.socialLink}>
            <TelegramIcon className={styles.socialIcon} />
          </a>
          <a href='https://x.com/' target='_blank' className={styles.socialLink}>
            <XIcon className={styles.socialIcon} />
          </a>
        </div>
        <a href='mailto:info@jeetus.lol' className={styles.email}>info@jeetus.lol</a>
      </div>
    </footer>
  )
}