import styles from './hero.module.scss';
import logo from '../../images/logo.png';
import codeRunning from '../../images/jesusbg.gif';

export const Hero = () => {
  return (
    <section className={styles.wrap}>
      <div className={styles.content}>
        <div className={styles.background}>
          <div className={styles.backgroundInner}>
            <img alt='code running' src={codeRunning} className={styles.backgroundImage} />
          </div>
        </div>
        <h1 className={styles.title}>$JEETUS</h1>
        <div className={styles.mainContent}>
          <img alt='logo' src={logo} className={styles.logoImage} />
          <p className={styles.subtitle}>REPENT FOR YOUR SINS</p>
          <p className={styles.text}>
            JEETUS DIES TODAY...AND WILL RISE AGAIN SUNDAY!
          </p>
        </div>
      </div>
      <div className={styles.buttonsWrap}>
        <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ' target='_blank' className={styles.link}>PUMP.FUN</a>
      </div>
    </section>
  )
}